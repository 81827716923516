<ng-container>
    <div class="form-section">
        <label for="nome" i18n>Nome do recurso</label>
        <input id="nome-recurso" type="text" name="nome" [(ngModel)]="recurso.nome">
    </div>
    <div class="form-section">
        <label for="altura" i18n>Altura</label>
        <input id="altura-recurso" type="number" name="altura" [(ngModel)]="recurso.tamanho" [min]="1" [max]="32" required #altura="ngModel"  [class.danger]="!altura.valid && !altura.pristine">
        <div class="alert danger" *ngIf="!altura.valid && !altura.pristine">
            <div *ngIf="altura.errors.max" i18n>Máximo 32</div>
            <div *ngIf="altura.errors.min" i18n>Mínimo 1</div>
            <div *ngIf="altura.errors.required" i18n>Este campo é obrigatório</div>
        </div>
    </div>
    <div class="form-section">
        <label for="largura" i18n>Largura</label>
        <input id="largura-recurso" type="number" name="largura" [(ngModel)]="recurso.largura" required [min]="1" [max]="192" #largura="ngModel" [class.danger]="!largura.valid && !largura.pristine">
        <div class="alert danger" *ngIf="!largura.valid && !largura.pristine">
            <div *ngIf="largura.errors.max" i18n>Máximo 192</div>
            <div *ngIf="largura.errors.min" i18n>Mínimo 1</div>
            <div *ngIf="largura.errors.required" i18n>Este campo é obrigatório</div>
        </div>
    </div>

    <div class="form-section">
        <canvas id="recurso-canvas" class='canvas' #canvas (click)="registerClick($event)"
          [attr.data-width]="recurso.largura"
          [attr.data-height]="recurso.tamanho"
        ></canvas>
    </div>
</ng-container>
