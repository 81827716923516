import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ProjetoApiService } from "../services/projeto-api.service";
import { HelpService } from "../services/help.service";

import { RoteiroApiService } from "@app/services/roteiro-api.service";
import { Projeto } from "@app/projeto.model";
import { PainelApiService } from "@app/services/painel-api.service";

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
    constructor(
        public projetoApi: ProjetoApiService,
        public roteiroApi: RoteiroApiService,
        public painelApi: PainelApiService,
        private titleService: Title,
        private help: HelpService,
    ) {}

    ngOnInit() {
        this.titleService.setTitle($localize `Home - Thoreb Itinerário`);
        this.help.local = "home";
    }
}
