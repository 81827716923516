import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { AsideOpenService } from '@app/services/aside-open.service';
import { AuthService } from '@app/services/auth.service';
import { HelpService } from '@app/services/help.service';
import { LoaderService } from '@app/services/loader.service';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

    public error = '';

    public user = {
        'email': '',
        'password': '',
    };
    public passwordToggled = false;

    public submiting = false;

    constructor(
        public asideOpen: AsideOpenService,
        private auth: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private titleService: Title,
        private help: HelpService,
        private loader: LoaderService,
    ) {
        this.asideOpen.isBlocked = true;
        this.titleService.setTitle($localize `Thoreb Itinerário`);
        this.help.local = 'login';
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.asideOpen.isBlocked = false;
    }

    togglePassword() {
        this.passwordToggled = !this.passwordToggled;
    }

    async submit() {
        this.submiting = true;
        this.loader.startLoading();
        try {
            await this.auth.login(this.user);
            this.router.navigate([this.route.snapshot.queryParams['returnUrl'] || '/']);
        } catch (error) {
            if (error.status == 401) {
                this.error = $localize `Informações incorretas`;
            } else if (error.status == 403) {
                this.error = $localize `Essa conta foi bloqueada`;
            } else {
                this.error = $localize `Algum erro desconhecido aconteceu. Por favor, tente novamente. Caso o erro persista, contate a Thoreb Brasil`;
            }
        } finally {
            this.submiting = false;
            this.loader.stopLoading();
        }
    }
}
