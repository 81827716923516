import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '@app/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class ElevationGuard implements CanActivate {

    constructor(
        private auth: AuthService
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        const currentUser = this.auth.user;
        if (currentUser && currentUser.administrador) {
            // authorised so return true
            return true;
        }
        return false;
    }
}
