import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-help-simulador',
    templateUrl: './help-simulador.component.html',
    styleUrls: ['./help-simulador.component.css']
})
export class HelpSimuladorComponent implements OnInit {

    @Output() fechar = new EventEmitter();


    constructor() { }

    ngOnInit() {
    }

}
