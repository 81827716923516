import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { pipe } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UsuarioApiService } from '@app/services/usuario-api.service';
import { LoaderService } from '@app/services/loader.service';
import { HelpService } from '@app/services/help.service';
import { Title } from '@angular/platform-browser';

import * as model from '@app/projeto.model';

@Component({
    selector: 'app-usuario-cadastrar',
    templateUrl: './usuario-cadastrar.component.html',
    styleUrls: ['./usuario-cadastrar.component.css']
})
export class UsuarioCadastrarComponent implements OnInit {

    public usuario: model.Usuario;

    constructor(
        private usuarioApi: UsuarioApiService,
        private router: Router,
        private loader: LoaderService,
        private help: HelpService,
        private titleService: Title
    ) {
        this.usuario = {
            nome: '',
            email: '',
            telefone: '',
            senha: '',
            tecnico: false,
        };
        this.help.local = 'empresa-usuario';
    }

    ngOnInit() {
        this.titleService.setTitle($localize `Cadastro de usuário - Thoreb Itinerário`);
    }

    public async salvar(usuario: model.Usuario) {
        this.loader.startLoading();
        try {
            await this.usuarioApi.salvarUsuario(usuario);
            this.router.navigate(['empresa']);
        } finally {
            this.loader.stopLoading();
        }
    }

}
