import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import * as model from '@app/projeto.model';
import { PainelApiService } from '@app/services/painel-api.service';
import { ProjetoApiService } from '@app/services/projeto-api.service';
import { RoteiroApiService } from '@app/services/roteiro-api.service';

@Component({
    selector: 'app-roteiros-form',
    templateUrl: './roteiros-form.component.html',
    styleUrls: ['./roteiros-form.component.css'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class RoteirosFormComponent {
    @Input() roteiro: model.Roteiro;
    @Input() outrosRoteiros: model.Roteiro[];
    @Input() i: number;
    @Input() movendo: model.Roteiro;

    @Output() excluir = new EventEmitter();
    @Output() mover = new EventEmitter();

    tooltips = {
        'edit': $localize`Editar`,
        'move': $localize`Ordenar`,
        'delete': $localize`Excluir`,
    };

    constructor(
        public projetosApi: ProjetoApiService,
        public roteiroApi: RoteiroApiService,
        public painelApi: PainelApiService,
        public router: Router,
    ) { }

    outrosRoteirosComp(roteiro: model.Roteiro) {
        return this.outrosRoteiros.filter(roteiroComp => roteiroComp != roteiro).map(roteiroComp => roteiroComp.cod);
    }

    roteiros_cods() {
        return this.outrosRoteiros.map(roteiroComp => roteiroComp.cod);
    }

    retornoValido() {
        const retorno = this.roteiro.cod_retorno;
        if (!retorno) return true;
        return this.roteiros_cods().find(c => c == retorno) != null;
    }

    async irEditor(r: model.Roteiro) {
        this.roteiroApi.select(r);
        const paineis = await this.painelApi.list_by_parent_id(r.parent_id);
        this.painelApi.select(paineis[0]);
        this.router.navigate(['editor']);
    }
}
