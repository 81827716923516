import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-help-tutorial',
  templateUrl: './help-tutorial.component.html',
  styleUrls: ['./help-tutorial.component.css']
})
export class HelpTutorialComponent implements OnInit {

    @Output() fechar = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }
}
