import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { UsuarioApiService } from '@app/services/usuario-api.service';
import { LoaderService } from '@app/services/loader.service';
import { HelpService } from '@app/services/help.service';
import { AuthService } from '@app/services/auth.service';
import * as model from '@app/projeto.model';


@Component({
    selector: 'app-empresa-lista',
    templateUrl: './empresa-lista.component.html',
    styleUrls: ['./empresa-lista.component.css']
})
export class EmpresaListaComponent implements OnInit {

    public empresas: model.Empresa[];

    constructor(
        private usuarioApi: UsuarioApiService,
        public auth: AuthService,
        private loader: LoaderService,
        private titleService: Title,
        private help: HelpService,
    ) {
        this.titleService.setTitle($localize `Lista de empresas - Thoreb Itinerário`);
        this.help.local = 'empresa';

        this.usuarioApi.getEmpresaAdmin().subscribe((empresas: model.Empresa[]) => {
            this.empresas = empresas;
        });
    }

    ngOnInit() {
    }

    alterarBloqueio(empresa: model.Empresa) {
        // empresa.bloqueado = !empresa.bloqueado;
        empresa.bloqueado = !empresa.bloqueado;
        this.usuarioApi.atualizarEmpresa(empresa).subscribe(resultado => {
            empresa.bloqueado = resultado.bloqueado;
        });
        // empresa.bloqueado = !empresa.bloqueado;
    }

    excluir(empresa: model.Empresa) {
        if (confirm($localize `Você tem certeza que deseja excluir esta empresa?`)) {
            this.usuarioApi.excluirEmpresa(empresa).subscribe(resultado => {
                if (this.empresas.find(empresaIt => empresa == empresaIt)) {
                    this.empresas.splice(this.empresas.findIndex(empresaIt => empresa == empresaIt),1);
                }
            });
        }
    }
}
