import { Injectable } from '@angular/core';
import { DesenhosApiService } from '../services/desenhos-api.service';
import * as model from '../projeto.model';

import { FontCalculator } from './font-calculator';


@Injectable({
    providedIn: 'root'
})
export class TemplateCalculator {

    constructor(
        private desenhosApi: DesenhosApiService,
        private fontCalculator: FontCalculator,
    ) {}

    public disponiveis = [
        {
            'id': 'padrao',
            'titulo': $localize `Padrão`,
            'alturaMinima': 0,
            'editable_width': false,
        },
        {
            'id': 'unico',
            'titulo': $localize `Grupo único`,
            'alturaMinima': 0,
            'editable_width': false,
        },
        {
            'id': 'duplo1',
            'titulo': $localize `Lado a lado`,
            'alturaMinima': 0,
            'editable_width': true,
        },
        {
            'id': 'triplo1',
            'titulo': $localize `Acima e abaixo`,
            'alturaMinima': 13,
            'editable_width': true,
        }
    ];


    async calcularTemplate(tipo: string, painel: model.Painel, prodFlag = false, custom_width: number = undefined): Promise<model.Grupo[]> {
        console.log("calcularTemplate com largura", custom_width);
        if (tipo == 'padrao') {
            return this.calcularPadrao(painel, prodFlag);
        } else if (tipo == 'unico') {
            return this.calcularUnico(painel, prodFlag);
        } else if (tipo == 'duplo1') {
            return this.calcularDuploLadoALado(painel, prodFlag, custom_width);
        } else if (tipo == 'triplo1') {
            return this.calcularTriplo(painel, prodFlag, custom_width);
        }
    }

    public getDisponiveis(painel: model.Painel) {
        return this.disponiveis.filter(disponivel => painel.dimension_y >= disponivel.alturaMinima);
    }

    private async calcularPadrao(painel: model.Painel, prodFlag: boolean): Promise<model.Grupo[]> {
        const fonte = await this.fontCalculator.calcularMelhorFonte(painel.dimension_y);
        const grupo = {
            'content': '',
            'spacing': 1,
            'invertido': false,
            'autoSize': true,
            'start_x': 0,
            'start_y': 0,
            'end_x': 0,
            'end_y': fonte.tamanho - 1,
            'offsetX': 0,
            'offsetY': 0,
            'efeito': 0,
            'origem': 1,
            'idOrigem': fonte.id,
            'cor': '#f2d21f'
        };

        return [grupo];
    }

    private async calcularUnico(painel: model.Painel, prodFlag: boolean): Promise<model.Grupo[]> {
        const fonte = await this.fontCalculator.calcularMelhorFonte(painel.dimension_y);
        const grupo = {
            'content': '',
            'spacing': 1,
            'invertido': false,
            'autoSize': false,
            'start_x': 0,
            'start_y': 0,
            'end_x': painel.dimension_x - 1,
            'end_y': painel.dimension_y - 1,
            'offsetX': 0,
            'offsetY': 0,
            'efeito': 0,
            'origem': 1,
            'idOrigem': fonte.id,
            'corFundo': prodFlag ? undefined :'#f2d21f',
            'cor': '#f2d21f',
        };

        return [grupo];
    }

    private async calcularDuploLadoALado(painel: model.Painel, prodFlag: boolean, custom_width: number = undefined): Promise<model.Grupo[]> {
        const fonte = await this.fontCalculator.calcularMelhorFonte(painel.dimension_y);
        // dividimos o painel em 2 grupos, 25/75 %

        let end_x = custom_width ?? Math.floor((painel.dimension_x - 1) * 0.25);
        console.log("calcularDuploLadoALado com largura", end_x);

        // grupo 1
        const grupo1 = {
            'content': '',
            'spacing': 1,
            'invertido': false,
            'autoSize': false,
            'start_x': 0,
            'start_y': 0,
            'end_x': end_x,
            'end_y': painel.dimension_y - 1,
            'efeito': 0,
            'origem': 1,
            'offsetX': 0,
            'offsetY': 0,
            'idOrigem': fonte.id,
            'corFundo': prodFlag ? undefined :'#2ffc3a',
            'cor': '#f2d21f',
        }

        // grupo 2
        const grupo2 = {
            'content': '',
            'spacing': 1,
            'invertido': false,
            'autoSize': false,
            'start_x': end_x + 1,
            'start_y': 0,
            'end_x': painel.dimension_x - 1,
            'end_y': painel.dimension_y - 1,
            'efeito': 0,
            'origem': 1,
            'offsetX': 0,
            'offsetY': 0,
            'idOrigem': fonte.id,
            'corFundo': prodFlag ? undefined :'#f2d21f',
            'cor': '#f2d21f',
        }

        return [grupo1, grupo2];
    }

    private async calcularTriplo(painel: model.Painel, prodFlag: boolean, custom_width: number = undefined): Promise<model.Grupo[]> {
        const fonte1 = await this.fontCalculator.calcularMelhorFonte(painel.dimension_y);
        const fonte2 = await this.fontCalculator.calcularMelhorFonte(Math.ceil((painel.dimension_y - 1) * 0.5) + 1);
        const fonte3 = await this.fontCalculator.calcularMelhorFonte(painel.dimension_y - Math.ceil((painel.dimension_y - 1) * 0.5) - 1);
        // dividmos o painel em 3 grupos, 25% / ---------

        let end_x = custom_width ?? Math.floor((painel.dimension_x - 1) * 0.25);

        // grupo 1
        const grupo1 = {
            'content': '',
            'spacing': 1,
            'invertido': false,
            'autoSize': false,
            'start_x': 0,
            'start_y': 0,
            'end_x': end_x,
            'end_y': painel.dimension_y - 1,
            'efeito': 0,
            'origem': 1,
            'offsetX': 0,
            'offsetY': 0,
            'idOrigem': fonte1.id,
            'corFundo': prodFlag ? undefined :'#2ffc3a',
            'cor': '#f2d21f',
        }

        // grupo 2
        const grupo2 = {
            'content': '',
            'spacing': 1,
            'invertido': false,
            'autoSize': false,
            'start_x': end_x + 1,
            'start_y': 0,
            'end_x': painel.dimension_x - 1,
            'end_y': Math.ceil((painel.dimension_y - 1) * 0.5),
            'efeito': 0,
            'origem': 1,
            'offsetX': 0,
            'offsetY': 0,
            'idOrigem': fonte2.id,
            'corFundo': prodFlag ? undefined :'#f2d21f',
            'cor': '#f2d21f',
        }

        // grupo 3
        const grupo3 = {
            'content': '',
            'spacing': 1,
            'invertido': false,
            'autoSize': false,
            'start_x': end_x + 1,
            'start_y': Math.ceil((painel.dimension_y - 1) * 0.5) + 1,
            'end_x': painel.dimension_x - 1,
            'end_y': painel.dimension_y - 1,
            'efeito': 0,
            'origem': 1,
            'offsetX': 0,
            'offsetY': 0,
            'idOrigem': fonte3.id,
            'corFundo': prodFlag ? undefined :'#ffffff',
            'cor': '#f2d21f',
        }

        return [grupo1, grupo2, grupo3];
    }
}
