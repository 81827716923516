import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-help-login',
  templateUrl: './help-login.component.html',
  styleUrls: ['./help-login.component.css']
})
export class HelpLoginComponent implements OnInit {

    @Output() fechar = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }
}
