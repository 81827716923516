import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';


import { EditorComponent } from './editor.component';
import { EditorMensagemComponent } from './editor-mensagem/editor-mensagem.component';
import { EditorViewComponent } from './editor-mensagem/editor-view/editor-view.component';
import { EditorFormFloatingComponent } from './editor-mensagem/editor-form-floating/editor-form-floating.component';
import { EditorTemplatesModalComponent } from './editor-templates-modal/editor-templates-modal.component';
import { EditorAtalhosModalComponent } from './editor-atalhos-modal/editor-atalhos-modal.component';

@NgModule({
    declarations: [
        EditorComponent,
        EditorMensagemComponent,
        EditorFormFloatingComponent,
        EditorViewComponent,
        EditorTemplatesModalComponent,
        EditorAtalhosModalComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
    ],
    exports: [
        EditorComponent,
        EditorViewComponent
    ]
})
export class EditorModule { }
