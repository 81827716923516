<h1 i18n>Tutorial</h1>
<p>
    <b i18n>
        Bem-vindo ao Software Editor Thoreb! Nesta página, vamos ensinar alguns termos próprios do editor e também te explicar como tudo funciona.
        Também disponibilizaremos alguns fluxogramas que te auxiliarão na compreensão de alguns processos do sistema. Vamos lá?
    </b>
    <br>
    <a href="assets/img/fluxograma-1.png" target="_blank" i18n>Fluxograma geral do sistema</a>
</p>
<h2 i18n>Projetos</h2>
<p i18n>
    Cada projeto se trata de um grupo de roteiros, ou, em outros termos, um tipo de ônibus com várias linhas dentro. Cada projeto possui uma quantidade de paineis dentro,
    então, pode-se pensar como um tipo de ônibus. Por exemplo: <br>
    Imaginamos um tipo de ônibus presente na sua frota, que chamamos de Interurbano 1. O Interurbano 1 possui 2 paineis, um frontal 13x128 e um lateral também 13x128.
    Também existe um modelo mais novo desse ônibus, que chamamos de Interurbano 2, que possui 1 painel frontal 8x80 e um lateral 13x128. <br>
    Podemos definir uma estrutura parecida com esta:
</p>
<ul>
    <li i18n>Projeto Interurbano 1
        <ul>
            <li>
                Paineis
                <ul>
                    <li>Painel 13x128 Frontal</li>
                    <li>Painel 13x128 Lateral</li>
                </ul>
            </li>
            <li>
                Roteiros
                <ul>
                    <li>Rodoviária para Centro</li>
                    <li>Centro para Vila Cristina</li>
                    <li>...</li>
                </ul>
            </li>
        </ul>
    </li>
    <li i18n>Projeto Interurbano 2
        <ul>
            <li>
                Paineis
                <ul>
                    <li>Painel 8x80 Frontal</li>
                    <li>Painel 13x128 Lateral</li>
                </ul>
            </li>
            <li>
                Roteiros
                <ul>
                    <li>Galópolis para Jardim América</li>
                    <li>Caxias para Feliz via Linha Temerária</li>
                    <li>...</li>
                </ul>
            </li>
        </ul>
    </li>
</ul>
<p i18n>
    Para cadastrar um projeto, basta você clicar no ícone de + na lista de projetos, ou clicar em <em>Novo projeto</em> no menu lateral. <br>
    <a href="assets/img/fluxograma-2.png" target="_blank">Fluxograma de cadastro de projetos</a>
</p>
<h2 i18n>Roteiros</h2>
<p i18n>
    Como vimos anteriormente, cada projeto possui uma lista de roteiros, que são linhas que um ônibus cumpre. Cada roteiro possui 2 propriedades: o nome do roteiro e um
    código de identificação. Os códigos de identificação não devem ser repetidos.<br>
    <strong>O código e o nome do roteiro aparecem no display do Itinerário para o motorista, por isso é importante que ambos sejam corretamente preenchidos</strong>
</p>
<h2 i18n>Editor de Mensagens</h2>
<p i18n>
    Chegamos na parte central do software! Aqui, é possível cadastrar as mensagens que serão exibidas no Itinerário. O software mostra um painel que representa o painel
    itinerário que está sendo editado. Cada mensagem será exibida após um tempo, que é configurável. <strong>Atenção!</strong> O tempo começa a contar após o término
    dos efeitos! <br>
</p>
<p i18n>
    As mensagens são compostas por <strong>grupos</strong>. Você pode conhecê-los como <strong>frames</strong>. Os grupos possuem um tamanho variável, representado pela
    borda vermelha. O tamanho pode ser alterado de duas formas: através do <strong>Auto Size</strong> e manualmente quando o Auto Size estiver desativado. <br>
    A função Auto Size atualiza o tamanho do campo automaticamente conforme você digita ou altera o tamanho do conteúdo do campo. Caso você não habilite esta função,
    novos controles de tamanho de grupo serão habilitados para que você possa alterá-lo manualmente.
</p>
<h3 i18n>Efeitos</h3>
<p i18n>
    Existem 3 efeitos disponíveis no sistema: Estático, Down e Rolagem.<br>
    O efeito estático não possui nenhuma transição, sendo utilizado quando é necessário que uma parte fique fixa no painel. É necessário que o grupo
    também esteja nas outras mensagens para que ele seja realmente fixo no painel.<br>
    O efeito down é um efeito que desce as letras de cima para baixo no grupo. <br>
    O efeito rolagem passa as letras do fim do grupo ao começo, incluindo texto que pode estar oculto durante o processo de cadastro do grupo. Isto é, o texto que passar
    do tamanho do grupo (as bordas vermelhas) será exibido também. <strong>É possível incluir apenas 1 grupo com efeito de rolagem por mensagem</strong>.
</p>
<h3 i18n>Desenhos</h3>
<p i18n>
    Estão disponíveis três modos de desenho nos Itinerários: Fonte, Recurso e Variável.
</p>
<h4 i18n>Fontes</h4>
<p i18n>
    O modo fonte é o modo texto normal, onde você pode selecionar a fonte que deseja e sair escrevendo.
</p>
<h5 i18n>Nomenclatura da fonte</h5>
<table>
    <tr>
        <th colspan="2">Thoreb Classic 13x7_Aa1</th>
    </tr>
    <tr>
        <th>Thoreb Classic</th>
        <td i18n>Nome da fonte</td>
    </tr>
    <tr>
        <th>13x7</th>
        <td i18n>Tamanho da fonte (x:7, y:13)</td>
    </tr>
    <tr>
        <th>Aa1</th>
        <td i18n>Maiúsculas, minúsculas e números</td>
    </tr>
</table>
<h4 i18n>Recursos</h4>
<p i18n>
    Os recursos são desenhos, cadastrados previamente, que podem ser utilizados em qualquer mensagem. É disponibilizado uma tela onde você pode cadastrar em um canvas
    novos recursos, pintados ponto a ponto. Com isso, é possível criar logos, ícones, desenhos, e tudo o mais que você imaginar!
</p>
<h4 i18n>Variáveis</h4>
<p i18n>
    Se você quiser uma informação dada pelo motorista, como o box em que o ônibus vai estar estacionado, ou a velocidade do ônibus, você pode usar as variáveis.
    Estes tipos de grupos são parecidos com as fontes, sendo que você pode alterar a fonte e o espaçamento. No entanto, ele vem com um texto pré-definido (geralmente, constituído dos números 9),
    que vai ser substituído pelos valores reais (sejam eles vindos do multiplex ou do painel) durante a execução do programa nos ônibus.
</p>
<h3 i18n>Replicar</h3>
<p i18n>
    A replicação de grupo é uma funcionalidade que agiliza o seu trabalho, de modo que você não precise ficar recriando grupos em cada mensagem diferente do painel.<br>
    A replicação funciona da seguinte maneira: Quando você clica no botão de replicar, o sistema tenta adicionar em todas as outras mensagens do painel uma cópia
    do grupo selecionado. Caso haja algum grupo preenchido ocupando o espaço, o sistema não faz a inserção. Caso haja um grupo vazio, ele é sobrescrito com o grupo
    replicado.
</p>
<p i18n>
    Também é possível replicar o conteúdo de um painel para outros. Neste caso, o sistema copiará o conteúdo do painel atual para os paineis vazios, calculando automaticamente
    a fonte e o tamanho mais próximos do conteúdo de origem. É sempre recomendável conferir as mensagens, pois o cálculo é feito por aproximação.
</p>
<h3 i18n>Transferência</h3>
<p i18n>
    Ok, você terminou de editar todas as mensagens e roteiros de um projeto. Para transferir para o painel e verificar nos Itinerários dos ônibus se está tudo tão bonito
    quanto você deixou, utilize o botão <em>Transferir</em>, do menu lateral. O software irá baixar um arquivo que o aplicativo Thoreb Itinerário transferirá para o
    painel.
</p>
<p i18n><strong>Mais alguma dúvida? Na barra superior, existe um botão de ajuda, que traz mais informações relacionadas à página que você está vendo!</strong></p>
<p i18n>Caso continue com dúvidas, envie e-mail para <a href="mailto:posvendas.br@thoreb.com">posvendas.br@thoreb.com</a>.</p>
