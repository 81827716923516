import { Injectable, OnInit } from '@angular/core';
import * as model from '../projeto.model';
import { DesenhosApiService } from './desenhos-api.service';
import { FontesApiService } from '@app/services/fontes-api.service';

@Injectable({
    providedIn: 'root'
})
export class TranslateResourcesService implements OnInit {
    public desenhos;

    constructor(
        private desenhosApi: DesenhosApiService,
        private fontesApi: FontesApiService,
    ) { }

    async ngOnInit() {
        this.desenhos = await this.desenhosApi.list_all();
    }

    public async translate(grupo: model.Grupo, strict = true) {
        const pontos: model.Ponto[] = [];

        let contColuna = -1;

        (await this.getPoints(grupo)).forEach(
            (letra: model.Ponto[], letraIndex: number) => {
                if (letra.length === 0) { // é espaço
                    contColuna += 2;
                }

                let colunaX = 0;

                letra.forEach((point: model.Ponto) => {
                    if (colunaX < point.x) {
                        // muda de coluna, serve no cálculo depois
                        // é necessário usar a diferença pois podem haver colunas sem pontos
                        contColuna += point.x - colunaX;
                        colunaX = point.x;
                    }

                    const pX = +grupo.start_x + +contColuna + (letraIndex * grupo.spacing) + (grupo.offsetSimX || 0) + +grupo.offsetX;
                    const pY = +grupo.start_y + (point.y - 1) + (grupo.offsetSimY || 0) + +grupo.offsetY;

                    const newPoint: model.Ponto = {
                        'x': pX,
                        'y': pY
                    };

                    // se passar das boundaries não adiciona
                    if ((strict && pX >= +grupo.start_x && pX <= +grupo.end_x && pY >= +grupo.start_y && pY <= +grupo.end_y) || !strict) {
                        pontos.push(newPoint);
                    }
                });
            }
        );

        return pontos;
    }

    private async getPoints(grupo: model.Grupo): Promise<model.Ponto[][]> {
        const desenhos = (await this.desenhosApi.list_all());
        const desenho: model.Desenho = desenhos .find(
            (desenho: model.Desenho) =>
                +desenho.origem == +grupo.origem
                && +desenho.id == +grupo.idOrigem
        );

        if(!desenho) {
            console.error("Grupo com desenho invalido", grupo, desenho);
            throw Error("Grupo com desenho invalido");
        }

        if (+desenho.origem === 1) { // Fonte
            let fonte = await this.fontesApi.get_by_id(desenho.getFonteId());
            return desenho.getPontos(grupo.content, fonte.letras);
        } else if (+desenho.origem === 2) { // Recurso
            return desenho.getPontos(grupo.content, []);
        } else if (+desenho.origem === 3) { // Variável
            let fonte = await this.fontesApi.get_by_id(desenho.getFonteId());
            // TODO: o objeto variavel percisa ter acesso a fonte para para poder pegar os pontos
            // talvez seja melhor mover essa lógica para um serviço separado.
            let variavel = desenho as model.Variavel;
            variavel.fonte = await this.fontesApi.get_by_id(variavel.getFonteId());
            return variavel.getPontos(grupo.content, fonte.letras);
        }

        console.error("Desenho com origem invalida", desenho);
    }

    public async getAutoSize(grupo: model.Grupo) {
        const tamanhos = [grupo.start_x, grupo.start_y];

        let contColuna = -1;

        (await this.getPoints(grupo)).forEach(
            (letra: model.Ponto[], letraIndex: number) => {
                let colunaX = 0;

                if (letra.length === 0) { // é espaço
                    contColuna += 2;

                    const pX = +grupo.start_x + contColuna + (letraIndex * grupo.spacing);

                    if (pX > tamanhos[0]) {
                        tamanhos[0] = pX;
                    }
                }

                letra.forEach((point: model.Ponto) => {

                    if (colunaX < point.x) {
                        // muda de coluna, serve no cálculo depois
                        // é necessário usar a diferença pois podem haver colunas sem pontos
                        contColuna += point.x - colunaX;
                        colunaX = point.x;
                    }

                    const pX = +grupo.start_x + +contColuna + (letraIndex * grupo.spacing) + +grupo.offsetX;
                    const pY = +grupo.start_y + (point.y - 1) + +grupo.offsetY;

                    if (pX > tamanhos[0]) {
                        tamanhos[0] = pX;
                    }

                    if (pY > tamanhos[1]) {
                        tamanhos[1] = pY;
                    }

                });
            }
        );

        return tamanhos;
    }
}
