import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioApiService } from '@app/services/usuario-api.service';
import { AuthService } from '@app/services/auth.service';

@Component({
    selector: 'app-tecnico-editar-senha',
    templateUrl: './tecnico-editar-senha.component.html',
    styleUrls: ['./tecnico-editar-senha.component.css']
})
export class TecnicoEditarSenhaComponent implements OnInit {

    public atual: string;
    public atualToggled: boolean;
    public nova: string;
    public novaToggled: boolean;

    public erro: string;
    public submitting: boolean;
    public regex = new RegExp('[A-Za-z].*[0-9]|[0-9].*[A-Za-z]', 'i');

    constructor(
        private api: UsuarioApiService,
        private auth: AuthService,
        private router: Router,
    ) { }

    ngOnInit() {
        this.atualToggled = false;
        this.novaToggled = false;
    }

    toggle(senha: string) {
        if (senha == 'atual') {
            this.atualToggled = !this.atualToggled;
        } else {
            this.novaToggled = !this.novaToggled;
        }
    }

    alterarSenha() {
        this.erro = "";
        this.submitting = true;
        this.api.alterarSenha(this.atual, this.nova, this.auth.user).subscribe(
            res => {
                console.log(res);
                this.submitting = false;
                this.router.navigate(['empresa']);
            },
            error => {
                console.log(error);
                if (error.status == 403) {
                    this.erro = $localize `Senha atual incorreta`;
                } else {
                    this.erro = $localize `Algum erro desconhecido aconteceu. Por favor, tente novamente. Caso o erro persista, contate a Thoreb Brasil`;
                }

                this.submitting = false;
            }
        );
    }

}
