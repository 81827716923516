import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';

import * as model from '@app/projeto.model';

@Component({
    selector: 'app-usuario-form',
    templateUrl: './usuario-form.component.html',
    styleUrls: ['./usuario-form.component.css'],
    viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class UsuarioFormComponent implements OnInit {

    private _usuario: model.Usuario;
    public oldUser: model.Usuario;
    public regex = new RegExp('[A-Za-z].*[0-9]|[0-9].*[A-Za-z]', 'i');
    public passwordToggled = false;

    @ViewChild('confirmacaoSenha') confirmacaoSenhaFormControl: NgModel;

    @Input()
    set usuario(usuario: model.Usuario) {
        this._usuario = usuario;
        this.oldUser = JSON.parse(JSON.stringify(usuario));
    }
    get usuario() {
        return this._usuario;
    }

    constructor() { }

    ngOnInit() {
    }

    togglePassword() {
        this.passwordToggled = !this.passwordToggled;
    }

    validatePasswordConfirmation(){
        this.confirmacaoSenhaFormControl.control.updateValueAndValidity()
    }

}
