import { Component, AfterViewInit, Input, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import * as model from '@app/projeto.model';

@Component({
    selector: 'app-fontes-form-recurso',
    templateUrl: './fontes-form-recurso.component.html',
    styleUrls: ['./fontes-form-recurso.component.css'],
    viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class FontesFormRecursoComponent implements AfterViewInit, OnChanges {

    @Input() recurso: model.Recurso;

    // precisamos registrar as alterações na detecção do angular, e esse é um hackzinho simples pra isso
    @Input() x: number;
    @Input() y: number;

    @ViewChild('canvas') public canvas: ElementRef;
    private cx: CanvasRenderingContext2D;
    private circlesVar;

    constructor() { }

    ngAfterViewInit() {
        const canvasEl = HTMLCanvasElement = this.canvas.nativeElement;
        this.cx = canvasEl.getContext('2d');

        this.setupCanvas();
    }

    ngOnChanges() {
        this.setupCanvas();
    }

    private setupCanvas() {
        if (this.cx !== undefined) {
            this.cx.canvas.width = this.recurso.largura * 20;
            this.cx.canvas.height = this.recurso.tamanho * 20;

            this.circlesVar = this.circles();
            this.gerarPontos();
            this.draw(this.circlesVar);
        }
    }

    public registerClick(click: MouseEvent) {
        const pos = {
            x: click.offsetX,
            y: click.offsetY
        };
        this.circlesVar.forEach((circle, index) => {
            if (this.isIntersect(pos, circle)) {
                this.circlesVar[index].on = !this.circlesVar[index].on;
                this.gerarPontos();
                this.draw(this.circlesVar);
            }
        });
    }


    public circles() {
        const pontos = [];
        for (let i = 0; i < this.recurso.largura; i++) {
            for (let j = 0; j < this.recurso.tamanho; j++) {
                let on = false;

                if (this.inPoints(i, j)) {
                    on = true;
                }

                pontos.push({
                    'x': (i * 20 + 8),
                    'y': (j * 20 + 8),
                    'radius': 8,
                    'on': on
                });
            }
        }
        return pontos;
    }

    public draw(pontos): void {
        this.cx.clearRect(0, 0, this.cx.canvas.width, this.cx.canvas.height); // limpar o canvas
        pontos.forEach((ponto) => {
            this.cx.beginPath();
            this.cx.arc(ponto.x, ponto.y, ponto.radius, 0, 2 * Math.PI, false);
            this.cx.fillStyle = ponto.on ? '#f2d21f' : '#333';
            this.cx.fill();

        });
    }

    private gerarPontos() {
        this.recurso.pontos = [];
        this.circlesVar.forEach((circle) => {
            const realX = (circle.x - 8) / 20 + 1;
            const realY = (circle.y - 8) / 20 + 1;
            if (circle.on && realX > 0 && realX <= this.recurso.largura && realY > 0 && realY <= this.recurso.tamanho) {
                this.recurso.pontos.push({
                    'x': realX,
                    'y': realY,
                });
            }
        });
    }

    private isIntersect(point, circle) {
        return Math.sqrt((point.x - circle.x) ** 2 + (point.y - circle.y) ** 2) < circle.radius;
    }
    private inPoints(x: number, y: number): boolean {
        let inPoint = false;

        this.recurso.pontos.forEach((ponto) => {
            if (+ponto.x - 1 === +x && +ponto.y - 1 === +y) {
                inPoint = true;
            }
        });

        return inPoint;
    }
}
