import { Component } from '@angular/core';

@Component({
  selector: 'app-locale-selector',
  templateUrl: './locale-selector.component.html',
  styleUrls: ['./locale-selector.component.css']
})
export class LocaleSelectorComponent {

}
