import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { HelpService } from '@app/services/help.service';
import * as model from '@app/projeto.model';

import { RecursosApiService } from '@app/services/recursos-api.service';

@Component({
    selector: "app-recursos-editar-page",
    templateUrl: "./recursos-editar-page.component.html",
    styleUrls: ["./recursos-editar-page.component.css"],
})
export class RecursosEditarPageComponent implements OnInit {
    public submitting = false;
    public recurso: model.Recurso;

    constructor(
        private route: ActivatedRoute,
        public recursosApi: RecursosApiService,
        private router: Router,
        private titleService: Title,
        private help: HelpService
    ) { }

    async ngOnInit() {
        this.help.local = "recursos";
        this.titleService.setTitle(
            $localize `Editar recurso - Thoreb Itinerário`
        );

        const id = +this.route.snapshot.paramMap.get('id');
        this.recurso = await this.recursosApi.get_by_id(id);
    }

    async submit() {
        this.submitting = true;
        await this.recursosApi.save(this.recurso)
        this.submitting = false;
        this.router.navigate(["recursos"]);
    }
}
