export interface Projeto {
    id?: number;
    encryptedId?: string;
    nomeEmpresa?: string;
    nome: string;
    descricao: string;
    data_alteracao?: Alteracao;
}

export interface Alteracao {
    date: Date;
    timezone: String;
    timezone_type: number;
}

export interface Painel {
    id?: number;
    dimension_x: number;
    dimension_y: number;
    localizacao: string;
    enderecoCan: number;
    parent_id: number;
}

export interface Roteiro {
    id?: number;
    cod: string;
    id_retorno?: number;
    cod_retorno?: string; // Usado internamente
    ordem: number;
    extra: boolean;
    nome: string;
    parent_id: number;
}

export interface Mensagem {
    id?: number;
    rstr?: number;
    ordem: number;
    tempo: number;
    descricao: string;
    idPainel: number;
    idRoteiro: number;
    grupos: Grupo[];
}

export interface Grupo {
    id?: number;
    rstr?: number;
    content: string;
    spacing: number;
    cor: string;
    invertido: boolean;
    autoSize: boolean;
    start_x: number;
    start_y: number;
    end_x: number;
    end_y: number;
    efeito: number;
    origem: number;
    idOrigem: number;
    offsetX: number;
    offsetY: number;
    offsetSimX?: number;
    endOffsetSimX?: number;
    offsetSimY?: number;
    endOffsetSimY?: number;
    corFundo?: string;
}

export interface Desenho {
    id?: number;
    origem: number;

    getPontos(content: string, letras: Letra[]): Ponto[][];
    getFonteId(): number;
}

export interface Letra {
    id?: number;
    parent_id: number;
    caractere: string;
    pontos: Ponto[];
    //detalhe de implementa de validação
    isDuplicated?: boolean;
    isEmpty?: boolean;
}

export interface Ponto {
    x: number;
    y: number;
}

export class Fonte implements Desenho {
    origem = 1;

    id?: number;
    rstr?: number;
    nome: string;
    tamanho: number;
    largura: number;
    letras?: Letra[];

    constructor(infos?: Object) {
        Object.assign(this, infos);
    }

    getPontos(content: string, letras: Letra[]): Ponto[][] {
        const pontos: Ponto[][] = [];

        content.split('').forEach((char) => {
            letras.forEach((letra) => {
                if (letra.caractere === char) {
                    pontos.push(letra.pontos);
                } else if (letra.caractere === '' && char === ' ') { // fix pro espaço
                    pontos.push(letra.pontos);
                }
            });
        });

        // return Array.from(this.letras, e => e.pontos);
        return pontos;
    }
    getFonteId() {
        return this.id;
    }
}


export class Recurso implements Desenho {
    id?: number;
    nome: string;
    tamanho: number;
    largura: number;
    origem = 2;

    pontos: Ponto[];

    constructor(infos?: Object) {
        Object.assign(this, infos);
    }

    getPontos(content: string): Ponto[][] {
        return [this.pontos];
    }
    getFonteId() {
        return undefined;
    }
}

export class Variavel implements Desenho {
    static readonly TIPO_HORA = 1;
    static readonly TIPO_TEMPERATURA = 2;
    static readonly TIPO_VELOCIDADE = 3;
    static readonly TIPO_BOX = 4;
    static readonly TIPO_DATA = 5;
    static readonly TIPO_SAIDA = 6;
    static readonly TIPO_CHEGADA = 7;

    id?: number;
    cod: string;
    tipoVariavel: number;
    fonte?: Fonte;
    idFonte?: number;
    origem = 3;

    constructor(infos?: Object) {
        Object.assign(this, infos);
    }

    getPontos(content: string, letras: Letra[]): Ponto[][] {
        if(!this.fonte || !(this.fonte instanceof Fonte)) {
            console.error("não é possível pegar os pontos de uma variavel sem essa variável ter acesso à fonte");
            throw new Error("Não é possível pegar os pontos de uma variavel sem essa variável ter acesso à fonte");
        }
        let texto = '';

        if (this.tipoVariavel == Variavel.TIPO_HORA) {
            texto = '99:99';
        }
        else if (this.tipoVariavel == Variavel.TIPO_TEMPERATURA) {
            texto = '99ºC';
        }
        else if (this.tipoVariavel == Variavel.TIPO_VELOCIDADE) {
            texto = '999km/h';
        }
        else if (this.tipoVariavel == Variavel.TIPO_BOX) {
            texto = '99';
        }
        else if (this.tipoVariavel == Variavel.TIPO_DATA) {
            texto = '99/99/99';
        }
        else if (this.tipoVariavel == Variavel.TIPO_SAIDA) {
            texto = '99:99';
        }
        else if (this.tipoVariavel == Variavel.TIPO_CHEGADA) {
            texto = '99:99';
        }

        return this.fonte.getPontos(texto, letras);
    }
    getFonteId(){
        return this.idFonte;
    }
}

export class Usuario {
    id?: number;
    idEmpresa?: number;
    nome: string;
    email: string;
    telefone: string;
    administrador?: boolean;
    tecnico: boolean;
    senha?: string;
    accessToken?: string;
    confirmacaoSenha?: string;
}

export class Empresa {
    id?: number;
    nome: string;
    cnpj: string;
    bloqueado: boolean;
    usuarios: Usuario[];
}

export class Contato {
    id?: number;
    nome: string;
    descricao: string;
    data?: Date;
}

export class ExportedProject {
    version: number;
    data: any;
}
