import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";

import { HelpService } from "@app/services/help.service";
import * as model from "@app/projeto.model";

import { RecursosApiService } from "@app/services/recursos-api.service";

@Component({
    selector: "app-recursos-cadastrar-page",
    templateUrl: "./recursos-cadastrar-page.component.html",
    styleUrls: ["./recursos-cadastrar-page.component.css"],
})
export class RecursosCadastrarPageComponent {
    public submitting = false;

    public recurso = new model.Recurso({
        nome: $localize `Recurso`,
        tamanho: 1,
        largura: 1,
        pontos: [],
    });

    constructor(
        public recursosApi: RecursosApiService,
        private router: Router,
        private titleService: Title,
        private help: HelpService
    ) {
        this.help.local = "recursos";
        this.titleService.setTitle(
            $localize `Adicionar recurso - Thoreb Itinerário`
        );
    }

    async submit() {
        this.submitting = true;
        await this.recursosApi.save(this.recurso);
        this.submitting = false;
        this.router.navigate(["recursos"]);
    }
}
