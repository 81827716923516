import { Component, Input, OnInit } from '@angular/core';
import { PainelApiService } from '../services/painel-api.service';
import { LoaderService } from '../services/loader.service';
import { Router } from '@angular/router';
import { fadeDown } from '@app/animations/fadeDown';
import * as model from '@app/projeto.model';
import { RoteiroApiService } from '@app/services/roteiro-api.service';
import { ProjetoApiService } from "@app/services/projeto-api.service";

@Component({
    selector: 'app-paineis-lista',
    templateUrl: './paineis-lista.component.html',
    styleUrls: ['./paineis-lista.component.css'],
    animations: [fadeDown]
})
export class PaineisListaComponent implements OnInit {
    private _projeto: model.Projeto;
    public paineis: model.Painel[] = undefined;
    @Input() set projeto(projeto) {
        this.paineis = undefined;
        this._projeto = projeto;
        try {
            this.loader.startLoading();
            this.painelApi.list_by_parent_id(projeto.id)
                .then(paineis => this.paineis = paineis);
        } finally {
            this.loader.stopLoading();
        }
    }
    get projeto() {
        return this._projeto;
    }

    constructor(
        public projetoApi: ProjetoApiService,
        public roteirosApi: RoteiroApiService,
        public painelApi: PainelApiService,
        public router: Router,
        public loader: LoaderService
    ) { }

    ngOnInit() {
        let selected = this.projetoApi.get_selected();
        if (selected)
            this.projeto = this.projetoApi.get_selected();
    }

    public editarPainel(p: model.Painel): void {
        this.painelApi.select(p);
        if (this.roteirosApi.get_selected()) {
            this.loader.startLoading();
            setTimeout(() => {
                this.router.navigateByUrl('/editor').then(() => {
                    this.loader.stopLoading();
                });
            }, 300);
        }
    }

}
