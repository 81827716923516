import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import * as model from '@app/projeto.model';

@Component({
    selector: 'app-empresa-form',
    templateUrl: './empresa-form.component.html',
    styleUrls: ['./empresa-form.component.css'],
    viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class EmpresaFormComponent implements OnInit {

    @Input() empresa: model.Empresa;

    constructor() { }

    ngOnInit() {
    }

}
