import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { UsuarioApiService } from '@app/services/usuario-api.service';
import { LoaderService } from '@app/services/loader.service';
import { HelpService } from '@app/services/help.service';
import { AuthService } from '@app/services/auth.service';
import * as model from '@app/projeto.model';

@Component({
    selector: 'app-tecnico-lista',
    templateUrl: './tecnico-lista.component.html',
    styleUrls: ['./tecnico-lista.component.css']
})
export class TecnicoListaComponent implements OnInit {

    public tecnicos: model.Usuario[];

    constructor(
        public usuarioApi: UsuarioApiService,
        public auth: AuthService,
        // private loader: LoaderService,
        private titleService: Title,
        private help: HelpService,
    ) {
        this.titleService.setTitle($localize `Lista de Técnicos - Thoreb Itinerário`);
        this.help.local = 'tecnico';
    }

    async ngOnInit() {
        this.tecnicos = await this.usuarioApi.listarUsuarios({ tecnico: true });
    }

    excluir(tecnico: model.Usuario) {
        if (confirm($localize `Você tem certeza que deseja excluir este tecnico?`)) {
            this.usuarioApi.excluirUsuario(tecnico).subscribe(async _ => {
                this.tecnicos = await this.usuarioApi.listarUsuarios({ tecnico: true });
            });
        }
    }
}
