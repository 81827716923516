import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ProjetoApiService } from '@app/services/projeto-api.service';
import { LoaderService } from '@app/services/loader.service';
import { HelpService } from '@app/services/help.service';
import { Alteravel } from '@app/alteravel';
import * as model from '@app/projeto.model';

import { PainelApiService } from '@app/services/painel-api.service';
import { ToastsService } from '@app/toasts.service';

@Component({
    selector: 'app-projetos-cadastrar',
    templateUrl: './projetos-cadastrar.component.html',
    styleUrls: ['./projetos-cadastrar.component.css']
})
export class ProjetosCadastrarComponent implements Alteravel {
    public alteracao: boolean;
    public projeto: model.Projeto;
    public paineis: model.Painel[];

    constructor(
        private projetosApi: ProjetoApiService,
        private paineisApi: PainelApiService,
        private router: Router,
        private loader: LoaderService,
        private toasts: ToastsService,
        private help: HelpService,
        private titleService: Title,
    ) {
        this.paineis = [];
        this.alteracao = false;
        this.projeto = {
            'nome': $localize `Novo projeto`,
            'descricao': '',
        };
        this.help.local = 'projetos';
        this.titleService.setTitle($localize `Cadastrar projeto - Thoreb Itinerário`);
    }

    async salvar({ projeto, paineis, paineis_para_excluir }: { projeto: model.Projeto, paineis: model.Painel[], paineis_para_excluir: model.Painel[] }) {
        try {
            this.loader.startLoading();

            const proj = await this.projetosApi.save(projeto);

            for (let painel of paineis_para_excluir) {
                await this.paineisApi.delete(painel);
            }
            for (let painel of paineis) {
                painel.parent_id = proj.id;
                await this.paineisApi.save(painel);
            }

            this.projetosApi.clear_caches();
            this.paineisApi.clear_caches();

            this.projetosApi.select(proj);
            this.router.navigate(['projetos', proj.id, 'roteiros']);
        } catch (e) {
            console.error(e);
            this.toasts.api_error("Falha ao salvar o projeto");
        } finally {
            this.loader.stopLoading();
        }
    }

    alterar() {
        this.alteracao = true;
    }
}
