import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

// import { DesenhosApiService } from '@app/services/desenhos-api.service';
import { HelpService } from '@app/services/help.service';
import * as model from '@app/projeto.model';


import { FontesApiService } from '@app/services/fontes-api.service';

import { LoaderService } from '@app/services/loader.service';

@Component({
    selector: 'app-fontes-page',
    templateUrl: './fontes-page.component.html',
    styleUrls: ['./fontes-page.component.css']
})
export class FontesPageComponent implements OnInit {
    fontes: model.Fonte[];
    editando: model.Fonte;

    tooltips = {
        'edit': $localize`Editar`,
        'dupe': $localize`Duplicar`,
        'delete': $localize`Excluir`,
    };

    constructor(
        public fontesApi: FontesApiService,
        private loader: LoaderService,
        private titleService: Title,
        private help: HelpService,
    ) {
    }

    async ngOnInit() {
        this.titleService.setTitle($localize`Fontes - Thoreb Itinerário`);
        this.help.local = 'fontes';

        try {
            this.loader.startLoading();
            this.fontes = structuredClone(await this.fontesApi.list_all());
        } finally {

            this.loader.stopLoading();
        }
    }

    editar(index: model.Fonte): void {
        if (this.editando === index) {
            this.editando = null;
        } else {
            this.editando = index;
        }
    }

    async salvarListener(event: salvarEvent) {
        try {
            this.loader.startLoading();
            const new_font = await this.fontesApi.save(event.fonte);

            this.fontes = structuredClone(await this.fontesApi.list_all());
            this.editando = this.fontes.find(fonte => fonte.id == new_font.id);
            event.callback(new_font.id);
        } catch {
            event.error_callback();
        } finally {
            this.loader.stopLoading();
        }
    }

    async novaFonte() {
        const new_font = new model.Fonte({
            'rstr': Date.now(),
            'origem': 1,
            'nome': $localize`Nova Fonte`,
            'tamanho': 8,
            'largura': 5,
            'letras': [],
        });
        this.fontes.push(new_font);
        this.editando = new_font;
    }

    async duplicarFonte(fonte: model.Fonte) {
        this.loader.startLoading();
        try {
            this.editando = await this.fontesApi.clone(fonte);
            this.fontes = structuredClone(await this.fontesApi.list_all());
        } finally {
            this.loader.stopLoading();
        }
    }

    async excluirFonte(fonte: model.Fonte, event: MouseEvent) {
        const botao: HTMLButtonElement = event.target as HTMLButtonElement;
        botao.disabled = true
        if (confirm($localize`Você tem certeza que deseja excluir essa fonte?`)) {
            try {
                this.loader.startLoading();
                await this.fontesApi.delete(fonte);
                this.fontes = await this.fontesApi.list_all();
                botao.disabled = false
            } finally {
                this.loader.stopLoading();
            }
        } else {
            botao.disabled = false
        }
    }
}

interface salvarEvent {
    fonte: model.Fonte;
    letras: model.Letra[];
    letras_excluidas: model.Letra[];
    callback: (new_font_id: number) => Promise<void>;
    error_callback: () => Promise<void>;
};
