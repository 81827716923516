import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { fadeDown } from '@app/animations/fadeDown';
import { RoteiroApiService } from '@app/services/roteiro-api.service';

import * as model from '@app/projeto.model';
import { PainelApiService } from '@app/services/painel-api.service';
import { Router } from '@angular/router';
import { LoaderService } from '@app/services/loader.service';
import { ProjetoApiService } from "@app/services/projeto-api.service";

@Component({
    selector: 'app-roteiros-lista',
    templateUrl: './roteiros-lista.component.html',
    styleUrls: ['./roteiros-lista.component.css'],
    animations: [fadeDown]
})
export class RoteirosListaComponent implements OnInit {
    public roteiros: model.Roteiro[] = undefined;
    private _projeto: model.Projeto;
    @Input() set projeto(projeto: model.Projeto) {
        this._projeto = projeto;
        this.carregarRoteiros();
    }
    get projeto() {
        return this._projeto;
    }
    constructor(
        public projetoApi: ProjetoApiService,
        public roteirosApi: RoteiroApiService,
        public painelApi: PainelApiService,
        public router: Router,
        public loader: LoaderService
    ) { }

    ngOnInit() {
        this.projeto = this.projetoApi.get_selected();
        this.carregarRoteiros();
    }

    async carregarRoteiros() {
        this.roteiros = undefined;
        try {
            this.loader.startLoading();
            let roteiros = await this.roteirosApi.list_by_parent_id(this.projeto.id);
            this.roteiros = [
                ...roteiros.filter(r => !r.extra),
                ...roteiros.filter(r => r.extra)
            ];
        } finally {
            this.loader.stopLoading();
        }
    }

    public editarRoteiro(p: model.Roteiro): void {
        this.roteirosApi.select(p);
        if (this.painelApi.get_selected()) {
            this.loader.startLoading();
            setTimeout(() => {
                this.router.navigateByUrl('/editor').then(() => {
                    this.loader.stopLoading();
                });
            }, 300);
        }
    }
}
