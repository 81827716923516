import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-help-landing',
    templateUrl: './help-landing.component.html',
    styleUrls: ['./help-landing.component.css']
})
export class HelpLandingComponent implements OnInit {

    @Output() fechar = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

}
