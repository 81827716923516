import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { environment } from "@app/../environments/environment";

import { Recurso } from "@app/projeto.model";
import { ApiClient } from "@app/api-client";

@Injectable({
    providedIn: "root",
})
export class RecursosApi implements ApiClient<Recurso, null> {
    private apiPoint = environment.endpoint;
    private json_options = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
        }),
    };

    constructor(
        private http: HttpClient
    ) { }

    cast(data): Recurso {
        if (!data.pontos) {
            console.error("valor inválido para pontos em resposta http", data);
            return data;
        }
        data.pontos = data.pontos
            .map(([x, y]) => {
                return {
                    x: +x,
                    y: +y
                }
            });

        return new Recurso(data);
    }

    api_endpoint(id: number | undefined = undefined): string {
        if (id == undefined) {
            return `${this.apiPoint}/recurso`;
        } else {
            return `${this.apiPoint}/recurso/${id}`;
        }
    }
    async get_by_id(id: number): Promise<Recurso> {
        let res = await this.http.get<Recurso>(this.api_endpoint(id)).toPromise();
        return this.cast(res);
    }
    async list_all(): Promise<Recurso[]> {
        let res = await this.http.get<Recurso[]>(this.api_endpoint()).toPromise();
        return res.map(this.cast);
    }
    async save(item: Recurso): Promise<Recurso> {
        let res;
        if (item.id !== undefined) {
            res = this.http
                .put<Recurso>(
                    this.api_endpoint(item.id),
                    item,
                    this.json_options
                )
                .toPromise();
        } else {
            res = this.http
                .post<Recurso>(
                    this.api_endpoint(),
                    item,
                    this.json_options
                )
                .toPromise();
        }
        return this.cast(await res);
    }
    delete(item: Recurso): Promise<boolean> {
        return this.http
            .delete<boolean>(this.api_endpoint(item.id), this.json_options)
            .toPromise();
    }
    clone(_item: Recurso): Promise<Recurso> {
        throw new Error("Method not implemented.");
    }

    // métodos não implementados pois fontes não tem parente
    get_parent(_item: Recurso): null {
        throw new Error("Method not implemented.");
    }
    list_by_parent_id(_parent_id: number): null {
        throw new Error("Method not implemented.");
    }
}
