import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { UsuarioApiService } from '@app/services/usuario-api.service';
import { LoaderService } from '@app/services/loader.service';
import { HelpService } from '@app/services/help.service';
import { AuthService } from '@app/services/auth.service';
import * as model from '@app/projeto.model';

@Component({
    selector: 'app-cadastrar-empresa',
    templateUrl: './cadastrar-empresa.component.html',
    styleUrls: ['./cadastrar-empresa.component.css']
})
export class CadastrarEmpresaComponent implements OnInit {

    public empresa: model.Empresa = {
        nome: '',
        cnpj: '',
        bloqueado: false,
        usuarios: [
            {
                nome: '',
                email: '',
                telefone: '',
                senha: '',
                tecnico: false,
            }
        ]
    };

    constructor(
        private usuarioApi: UsuarioApiService,
        public auth: AuthService,
        private loader: LoaderService,
        private router: Router,
        private titleService: Title,
        private help: HelpService
    ) {
        this.titleService.setTitle($localize `Cadastrar Empresa - Thoreb Itinerário`);
        this.help.local = 'empresa';
    }

    ngOnInit() {
    }

    cadastrar() {
        this.loader.startLoading();
        this.usuarioApi.salvarEmpresa(this.empresa).subscribe((data) => {
            this.loader.stopLoading();
            this.router.navigate(['empresa', 'listar']);
        });
    }
}
