import { Injectable } from '@angular/core';
import { ProjetoApi } from './projeto.api';
import { ContextOptionsService, Action } from './context-options.service';
import { saveAs } from 'file-saver';
import { Projeto, ExportedProject } from '@app/projeto.model';

import { ErrorHandlerService } from '@app/shared/services/error-handler.service';
import { EntityService } from '@app/entity-service';
import { ToastsService } from '@app/toasts.service';

import { RoteiroApiService } from './roteiro-api.service';
import { PainelApiService } from './painel-api.service';

@Injectable({
    providedIn: 'root'
})
export class ProjetoApiService implements EntityService<Projeto, null> {
    private projetos: Projeto[];
    private projetos_by_id: Record<number, Projeto> = {};
    private ponteiroProjeto: Projeto;

    constructor(
        private toasts: ToastsService,
        private api: ProjetoApi,
        private contextOptions: ContextOptionsService,
        private errorHandler: ErrorHandlerService,
        private painelApi: PainelApiService,
        private roteiroApi: RoteiroApiService,
    ) { }

    async list_all(): Promise<Projeto[]> {
        if (this.projetos != undefined)
            return this.projetos;
        const close_toast = this.toasts.api_info($localize `Buscando projetos`);
        try {
            this.projetos = await this.api.list_all();
            return this.projetos;
        } catch (err) {
            console.error("Error loading projects", err);
            this.toasts.api_error(
                $localize `Erro ao carregar os projetos`
            )
        } finally {
            close_toast();
        }
    }

    async get_by_id(id: number): Promise<Projeto> {
        if (this.projetos_by_id[id])
            return this.projetos_by_id[id];
        let projetos = await this.list_all();
        let cached = projetos.find(projeto => projeto.id == id);
        if (cached) {
            this.projetos_by_id[id] = cached;
            return cached;
        }
        const close_toast = this.toasts.api_info($localize `Buscando projeto`);
        try {
            this.projetos_by_id[id] = await this.api.get_by_id(id);
            return this.projetos_by_id[id]
        } catch (err) {
            console.error("Error loading project by id", err);
        } finally {
            close_toast();
        }
    }

    async save(projeto: Projeto): Promise<Projeto> {
        const close_toast = this.toasts.api_info($localize `Salvando projeto`);
        try {
            const data = await this.api.save(projeto);
            this.toasts.api_success($localize `Salvo com sucesso`);
            this.replaceProjeto(projeto, data);
            return data;
        } catch (err) {
            console.error("Error saving project", err);
        } finally {
            close_toast();
        }
    }

    async delete(item: Projeto) {
        const close_toast = this.toasts.api_info($localize `Excluindo o projeto`);
        const remover_projeto = () => {
            this.toasts.api_success($localize `Excluído com sucesso`);
            this.projetos = this.projetos
                .filter((projetoIt) => projetoIt.id != item.id);
            this.ponteiroProjeto = undefined;
            // TODO: remover isso junto com a adição dos contextOptions
            this.contextOptions.limparPorLabel('api');
        };

        try {
            remover_projeto();
            if (item.id != undefined)
                await this.api.delete(item);
            return true;
        } catch (err) {
            console.error("Error saving project", err);
            this.toasts.api_error($localize `Não foi possível excluir o projeto`);
            return false;
        } finally {
            close_toast();
        }
    }


    async clone(item: Projeto): Promise<Projeto> {
        const close_toast = this.toasts.api_info($localize `Duplicando projeto`);
        try {
            const data = await this.api.clone(item);
            this.toasts.api_success($localize `Duplicado com sucesso`);
            this.projetos = [...(this.projetos), data];
            return data;
        } catch (err) {
            console.error("Error cloning project", err);
            this.toasts.api_error($localize `Não foi possível duplicar o projeto`);
        } finally {
            close_toast();
        }
    }

    // Esses dois métodos não fazem sentido para projeto já que os projetos
    // são as entidades raiz da estrutura de dados
    list_by_parent_id(_parent_id: number): Promise<Projeto[]> {
        throw new Error('Method not implemented.');
    }
    get_parent(_item: Projeto): null {
        throw new Error('Method not implemented.');
    }

    // Gerenciar o projeto ativo atualmente
    select(item: Projeto) {
        this.ponteiroProjeto = item;

        // TODO: fazer isso direito
        // adicionar as ações que existem ao selecionar o painel
        this.contextOptions.limparPorLabel('api');
        this.contextOptions.actions.push(
            new Action('api', $localize `Editar projeto`, 'icon-edit', this.contextOptions.router, 'navigate', [['projetos', 'editar', this.get_selected().id]]),
            new Action('api', $localize `Roteiros`, 'icon-menu', this.contextOptions.router, 'navigate', [['projetos', this.get_selected().id, 'roteiros']]),
            new Action('api', $localize `Simulador`, 'icon-play', this.contextOptions.router, 'navigate', [['projetos', this.get_selected().id, 'simulador']]),
            // new Action('api', 'Transferir', 'icon-transfer', {'func': (idProjeto) => window.location.href = 'http://192.168.2.250:8080/Itinerario/API/bitmap/' + idProjeto}, 'func', [this.getProjeto().id]),
            new Action('api', $localize `Transferir`, 'icon-transfer', this, 'baixarProjeto', [this.get_selected()]),
            new Action('api', $localize `Imprimir`, 'icon-print', this, 'imprimirProjeto', [this.get_selected()]),
            new Action('api', $localize `Exportar`, 'icon-export', this, 'exportarProjeto', [this.get_selected()]),
        );
        this.painelApi.clear_selected();
        this.roteiroApi.clear_selected();
        this.painelApi.clear_caches();
        this.roteiroApi.clear_caches();
    }
    get_selected(): Projeto {
        return this.ponteiroProjeto;
    }
    is_selected(p: Projeto): boolean {
        return this.ponteiroProjeto && this.ponteiroProjeto.id == p.id;
    }
    clear_selected() {
        this.ponteiroProjeto = undefined;
    }

    clear_caches() {
        this.projetos = undefined;
        this.projetos_by_id = {};
    }

    //////////////////////////
    //    Métodos únicos    //
    //////////////////////////

    public async refreshProjetos() {
        this.clear_selected();
        return await this.list_all();
    }

    public replaceProjeto(old: Projeto, newP: Projeto) {
        if(!this.projetos) return;
        if (old.id == undefined)
            return this.projetos.push(newP);

        this.projetos_by_id[old.id] = newP;
        this.projetos = this.projetos.map(
            (proj: Projeto) => (+proj.id === +old.id) ? newP : proj
        )
    }

    public async baixarProjeto(projeto: Projeto) {
        try {
            const data = await this.api.baixarProjeto(projeto.id);
            const blob = <any>(new Blob([data], { type: 'application/thd' }));
            saveAs(blob, `${projeto.nome}.thd`);
        } catch (error) {
            this.errorHandler.handleError(error)
        }
    }

    public async imprimirProjeto(projeto: Projeto) {
        try {
            const data = await this.api.imprimirProjeto(projeto.id);
            const blob = <any>(new Blob([data], { type: 'application/x-pdf' }));
            saveAs(blob, `${projeto.nome}.pdf`);
        } catch (error) {
            this.errorHandler.handleError(error);
        }
    }

    public async exportarProjeto(projeto: Projeto) {
        try {
            const data = await this.api.exportarProjeto(projeto.id);
            const blob = <any>(new Blob([data], { type: 'application/json' }));
            saveAs(blob, `${projeto.nome}.json`);
        } catch (error) {
            this.errorHandler.handleError(error)
        }
    }
    public async importarProjeto(projeto: ExportedProject) {
        const close_toast = this.toasts.api_info($localize `Importando projeto`);
        try {
            const data = await this.api.importarProjeto(projeto);
            this.toasts.api_success($localize `Salvo com sucesso`);
            return data;
        } catch (err) {
            console.error("Error saving project", err);
        } finally {
            close_toast();
        }
    }
}
