import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-help-empresa',
  templateUrl: './help-empresa.component.html',
  styleUrls: ['./help-empresa.component.css']
})
export class HelpEmpresaComponent implements OnInit {

    @Output() fechar = new EventEmitter();

     constructor() { }

     ngOnInit() {
     }
}
