import { Component, OnInit } from '@angular/core';
import { HelpService } from '@app/services/help.service';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {

    constructor(
        private help: HelpService,
        private titleService: Title
    ) { }

    ngOnInit() {
        this.titleService.setTitle($localize `Thoreb Itinerário`);
        this.help.local = 'landing';
    }

}
