import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ElementRef,
    AfterViewInit,
    Output,
    EventEmitter,
    OnChanges,
} from "@angular/core";
import * as model from "@app/projeto.model";

@Component({
    selector: "app-letra-deletada",
    templateUrl: "./letra-deletada.component.html",
    styleUrls: ["./letra-deletada.component.css"],
})
export class LetraDeletadaComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() letra: model.Letra;
    @Input() canvasX: number;
    @Input() canvasY: number;
    @Input() i: number;

    @Output() restaurar$ = new EventEmitter();

    @ViewChild("canvas") public canvas: ElementRef;
    private cx: CanvasRenderingContext2D;
    private circlesVar;

    constructor() { }

    ngOnInit() {
        console.log(this.letra);
    }

    ngAfterViewInit() {
        const canvasEl = (HTMLCanvasElement = this.canvas.nativeElement);
        this.cx = canvasEl.getContext("2d");

        this.setupCanvas();
    }

    ngOnChanges() {
        this.setupCanvas();
        console.log(this.letra);
    }

    private setupCanvas() {
        if (this.cx !== undefined) {
            this.cx.canvas.width = this.canvasX * 20;
            this.cx.canvas.height = this.canvasY * 20;

            this.circlesVar = this.circles();
            this.gerarPontos();
            this.draw(this.circlesVar);
        }
    }

    public restaurar() {
        this.restaurar$.emit(this.letra);
    }

    public circles() {
        const pontos = [];
        for (let i = 0; i < this.canvasX; i++) {
            for (let j = 0; j < this.canvasY; j++) {
                let on = false;

                if (this.inPoints(i, j)) {
                    on = true;
                }

                pontos.push({
                    x: i * 20 + 8,
                    y: j * 20 + 8,
                    radius: 8,
                    on: on,
                });
            }
        }
        return pontos;
    }

    public draw(pontos): void {
        this.cx.clearRect(0, 0, this.cx.canvas.width, this.cx.canvas.height); // limpar o canvas
        pontos.forEach((ponto) => {
            this.cx.beginPath();
            this.cx.arc(ponto.x, ponto.y, ponto.radius, 0, 2 * Math.PI, false);
            this.cx.fillStyle = ponto.on ? "#f2d21f" : "#333";
            this.cx.fill();
        });
    }

    private gerarPontos() {
        this.letra.pontos = [];
        this.circlesVar.forEach((circle) => {
            const realX = (circle.x - 8) / 20 + 1;
            const realY = (circle.y - 8) / 20 + 1;
            if (
                circle.on &&
                realX > 0 &&
                realX <= this.canvasX &&
                realY > 0 &&
                realY <= this.canvasY
            ) {
                this.letra.pontos.push({
                    x: realX,
                    y: realY,
                });
            }
        });
    }

    private inPoints(x: number, y: number): boolean {
        let inPoint = false;

        this.letra.pontos.forEach((ponto) => {
            if (+ponto.x - 1 === +x && +ponto.y - 1 === +y) {
                inPoint = true;
            }
        });

        return inPoint;
    }
}
