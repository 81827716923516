import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { pipe } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UsuarioApiService } from '@app/services/usuario-api.service';
import { LoaderService } from '@app/services/loader.service';
import { HelpService } from '@app/services/help.service';
import { Title } from '@angular/platform-browser';


import * as model from '@app/projeto.model';

@Component({
    selector: 'app-usuario-editar',
    templateUrl: './usuario-editar.component.html',
    styleUrls: ['./usuario-editar.component.css']
})
export class UsuarioEditarComponent implements OnInit {

    public usuario: model.Usuario;

    constructor(
        private usuarioApi: UsuarioApiService,
        private route: ActivatedRoute,
        private router: Router,
        private loader: LoaderService,
        private help: HelpService,
        private titleService: Title
    ) {
        this.loader.startLoading();

        const id = +this.route.snapshot.paramMap.get('id');
        this.usuarioApi.getEmpresa().subscribe((empresa: model.Empresa) => {
            const usuario = empresa.usuarios.find((usuario: model.Usuario) => usuario.id == id);
            this.loader.stopLoading();
            if (usuario) {
                this.usuario = JSON.parse(JSON.stringify(usuario));
            } else {
                this.router.navigateByUrl('/empresa');
            }
        });
        this.help.local = 'empresa-usuario';
    }

    ngOnInit() {
        this.titleService.setTitle($localize `Editar usuário - Thoreb Itinerário`);
    }

    public async salvar(usuario: model.Usuario) {
        this.loader.startLoading();
        try {
            await this.usuarioApi.salvarUsuario(usuario);
            this.router.navigate(['empresa']);
        } finally {
            this.loader.stopLoading();
        }
    }

}
