import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-help-home',
    templateUrl: './help-home.component.html',
    styleUrls: ['./help-home.component.css']
})
export class HelpHomeComponent implements OnInit {

    @Output() fechar = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

}
