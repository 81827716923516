import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ChangeCalculator } from '@app/editor/change-calculator';
import * as model from '@app/projeto.model';


@Component({
    selector: 'app-projetos-form',
    templateUrl: './projetos-form.component.html',
    styleUrls: ['./projetos-form.component.css']
})
export class ProjetosFormComponent implements OnInit {
    @Input() projeto: model.Projeto;
    @Input() paineis: model.Painel[];
    @Output() salvar = new EventEmitter();
    private paineis_para_excluir: model.Painel[] = [];

    constructor(
        private calculator: ChangeCalculator,
    ) { }

    ngOnInit() {
    }

    submit() {
        this.salvar.emit({
            projeto: this.projeto,
            paineis: this.paineis,
            paineis_para_excluir: this.paineis_para_excluir,
        });
    }


    novoPainel() {
        this
            .paineis
            .push({
                'dimension_x': 128,
                'dimension_y': 13,
                'localizacao': $localize `Frontal`,
                // 'enderecoCan': this.projeto.paineis.length > 0 ? Math.max(...this.projeto.paineis.map((e) => +e.enderecoCan)) + 1 : 101
                'enderecoCan': undefined,
                'parent_id': undefined,
            });
    }

    excluir_painel(painel: model.Painel) {
        this.paineis = this.paineis.filter(
            p => p != painel
        );
        this.paineis_para_excluir.push(
            painel
        );
    }

    track(index, element) {
        return index;
    }

    // async adaptarGrupos(paineis: model.Painel[]) {
    //     const atual = paineis[0];
    //     const old = paineis[1];
    //     const roteiros = this.projeto.roteiros.map((roteiro: model.Roteiro) => {
    //         roteiro.mensagens = roteiro.mensagens.map((mensagem: model.Mensagem) => mensagem.idPainel == atual.id ? this.calculator.calculate(mensagem, old, atual) : mensagem);
    //         return roteiro;
    //     });

    //     this.projeto.roteiros = roteiros;
    // }
}
