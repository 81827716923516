import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContatoApiService } from '@app/services/contato-api.service';
import { Title } from '@angular/platform-browser';

import * as model from '@app/projeto.model';


@Component({
    selector: 'app-contato',
    templateUrl: './contato.component.html',
    styleUrls: ['./contato.component.css']
})
export class ContatoComponent implements OnInit {

    public contato: model.Contato = {
        "nome": "",
        "descricao": "",
        "data": null,
    };

    public submitting = false;

    constructor(
        private api: ContatoApiService,
        private router: Router,
        private titleService: Title,
    ) { }

    ngOnInit() {
        this.titleService.setTitle($localize `Feedback - Thoreb Itinerário`);
    }

    enviar() {
        this.submitting = true;
        this.api.enviarContato(this.contato).subscribe(
            () => {
                this.submitting = false;
                this.router.navigate(['home']);
            }
        );
    }
}
